import React from 'react';
import styled from 'styled-components';
import slugify from 'slugify';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  COLOR_DARK_GREEN,
  COLOR_TEXT_GRAY,
  COLOR_GRAY,
  MAX_WIDTH,
  MAX_WIDTH_SITE,
  screen,
  StyledLink,
} from '../components/common/variables';
import SEO from '../components/seo';
import Layout from '../components/layout';

const Banner = styled.div`
  max-width: ${MAX_WIDTH_SITE};
  margin: 0 auto;
  @media ${screen.largeXL} {
    padding-top: 0;
  }

  .small-banner {
    max-width: 100%;
    @media ${screen.largeXL} {
      display: none;
    }
  }
  .large-banner {
    display: none;
    @media ${screen.largeXL} {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  max-width: ${MAX_WIDTH};
  margin: 0 auto 145px auto;
  @media ${screen.largeXL} {
    margin: 0 auto 90px auto;
  }

  .services {
    max-width: ${MAX_WIDTH};
    margin: 45px auto 0 auto;
    padding: 0 35px;
    @media ${screen.largeXL} {
      padding: 0;
      margin: 60px auto 0 auto;
    }

    &__page-title {
      font-size: 1.8rem;
      font-weight: 500;
      margin: 0;
      color: ${COLOR_GRAY};
      @media ${screen.medium} {
        text-align: center;
        margin: 0 0 10px 0;
        font-size: 2.1rem;
      }
      @media ${screen.largeXL} {
        font-size: 2.4rem;
      }
    }

    &__page-description {
      p {
        font-size: 1rem;
        margin: 15px auto 0 auto;
        max-width: 700px;
        color: ${COLOR_TEXT_GRAY};
        @media ${screen.medium} {
          margin: 30px auto 0 auto;
          font-size: 1.1rem;
          text-align: center;
        }
      }
    }

    &__list {
      margin: 45px auto 0 auto;
      @media ${screen.medium} {
        margin: 90px auto 0 auto;
      }
    }

    &__service {
      display: flex;
      margin-bottom: 60px;
      flex-wrap: wrap;

      justify-content: center;
      @media ${screen.medium} {
        margin-bottom: 90px;
      }

      .links {
        display: block;
        max-width: 100%;
        text-align: center;
        @media ${screen.medium} {
          display: table;
          max-width: auto;
        }
      }
    }

    &__service-texts {
      max-width: 460px;
      @media ${screen.largeXL} {
        max-width: 645px;
      }
    }

    &__service-name {
      font-weight: 500;
      color: ${COLOR_DARK_GREEN};
      margin: 20px 0 5px 0;
      font-size: 1.4rem;
      @media ${screen.medium} {
        font-size: 1.65rem;
      }
      @media ${screen.largeXL} {
        margin: 0 0 10px 0;
        font-size: 2rem;
      }
    }

    &__service-description {
      margin: 0 0 30px 0;
      font-size: 1rem;
      color: ${COLOR_TEXT_GRAY};
      @media ${screen.medium} {
        font-size: 1.1rem;
        margin: 0 0 40px 0;
      }
    }

    &__service-img {
      width: 280px;
      align-self: center;
      display: none;
      @media ${screen.medium} {
        display: block;
      }
      @media ${screen.large} {
        width: 250px;
        margin: 0 50px 0 0;
      }
    }

    &__green-text {
      color: ${COLOR_DARK_GREEN};
    }
  }
`;

const ServicesPage = ({ data }) => {
  const { acfServicesPage } = data.content;
  const description = data.content.content;
  const services = acfServicesPage.services;

  const renderServices = services.map((service) => {
    return (
      <li className="services__service" key={service.title}>
        <div className="services__service-img">
          <Link to={`/services/${slugify(service.title, { lower: true })}`}>
            <GatsbyImage
              image={service.image.localFile.childImageSharp.gatsbyImageData}
              alt={service.title}
            />
          </Link>
        </div>
        <div className="services__service-texts">
          <h2 className="services__service-name">{service.title}</h2>
          <p className="services__service-description">
            {service.summarizedDescription}
          </p>
          <StyledLink
            className="links"
            to={`/services/${slugify(service.title, { lower: true })}`}
          >
            Find out more
          </StyledLink>
        </div>
      </li>
    );
  });
  const pageTitle =
    (acfServicesPage.seo && acfServicesPage.seo.pageTitle) || 'Services';
  const pageDescription =
    acfServicesPage.seo && acfServicesPage.seo.description;
  return (
    <Layout>
      <SEO title={pageTitle} description={pageDescription} />
      <Banner>
        <GatsbyImage
          className="large-banner"
          image={
            acfServicesPage.imageBanner.localFile.childImageSharp
              .gatsbyImageData
          }
          alt="Source Separation Systems Services"
        />

        <div className="small-banner">
          <GatsbyImage
            image={
              data.smallScreenImg.acfServicesPage.imageBanner.localFile
                .childImageSharp.gatsbyImageData
            }
            alt="Source Separation Systems Services"
          />
        </div>
      </Banner>
      <Wrapper>
        <div className="services">
          <h1 className="services__page-title">
            <span className="services__green-text">Our</span> Services
          </h1>
          <div
            className="services__page-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <ul className="services__list">{renderServices}</ul>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default ServicesPage;

export const servicesData = graphql`
  {
    content: wpPage(id: { eq: "cG9zdDo0Mw==" }) {
      id
      content
      acfServicesPage {
        seo {
          description
          pageTitle
        }
        services {
          title
          summarizedDescription
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        imageBanner {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }

    smallScreenImg: wpPage(id: { eq: "cG9zdDo0Mw==" }) {
      acfServicesPage {
        imageBanner {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 900, height: 400)
            }
          }
        }
      }
    }
  }
`;
